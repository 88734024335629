import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import QRCodeScanner from './components/QRCodeScanner';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { v4 as uuidv4 } from 'uuid';

// Initialize GA with your measurement ID
ReactGA.initialize("G-RXV9V5YWGE"); 

// Lazy load components
const ResultPage = lazy(() => import('./components/ResultPage'));
const TermsOfService = lazy(() => import('./TermsOfService'));
const PrivacyNotice = lazy(() => import('./PrivacyNotice'));
const About = lazy(() => import('./About'));

// Function to generate a UUID as API key
const generateApiKey = () => {
  return uuidv4();
};

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(Cookies.get("cookiesAccepted") === "true");
  const [apiKey, setApiKey] = useState(null);

  useEffect(() => {
    if (cookiesAccepted) {
      ReactGA.initialize("G-RXV9V5YWGE");
    }

    // Check for existing API key in localStorage
    let storedApiKey = localStorage.getItem('apiKey');
    if (!storedApiKey) {
      storedApiKey = generateApiKey();
      localStorage.setItem('apiKey', storedApiKey);
    }
    setApiKey(storedApiKey);
  }, [cookiesAccepted]);

  const handleAcceptCookie = () => {
    setCookiesAccepted(true);
    Cookies.set("cookiesAccepted", "true", { expires: 150 });
  };

  const handleDeclineCookie = () => {
    setCookiesAccepted(false);
    Cookies.set("cookiesAccepted", "false", { expires: 150 });
  };

  return (
    <Router>
      <AppContent cookiesAccepted={cookiesAccepted} apiKey={apiKey} />
      <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="IsThisQRSafe"
      containerClasses="my-custom-container"
      buttonClasses="my-custom-button"
      contentClasses="my-custom-content"
      declineButtonClasses="my-custom-decline-button"
      style={{ background: "#2B373B" }}
      buttonStyle={{ background: "#4e503b", color: "#fff" }}
>
        This website uses cookies to enhance the user experience and for analytics.
      </CookieConsent>
    </Router>
  );
}

function AppContent({ cookiesAccepted, apiKey }) {
  const location = useLocation();

  useEffect(() => {
    if (cookiesAccepted) {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location, cookiesAccepted]);

  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<QRCodeScanner apiKey={apiKey} />} />
          <Route path="/result" element={<ResultPage apiKey={apiKey} />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyNotice />} />
          <Route path="/about" element={<About />} />
          {/* Catch-all route to redirect to home */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
