// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  height: auto;
  min-height: 100vh;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  html, body {
    font-size: 0.9rem; /* Slightly smaller font size for tablets */
  }
}

@media screen and (max-width: 480px) {
  html, body {
    font-size: 0.8rem; /* Smaller font size for mobile phones */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA,sBAAsB;AACtB;EACE;IACE,iBAAiB,EAAE,2CAA2C;EAChE;AACF;;AAEA;EACE;IACE,iBAAiB,EAAE,wCAAwC;EAC7D;AACF;;AAEA;EACE;aACW;AACb","sourcesContent":["html, body {\n  height: auto;\n  min-height: 100vh;\n}\n\n/* Responsive Design */\n@media screen and (max-width: 768px) {\n  html, body {\n    font-size: 0.9rem; /* Slightly smaller font size for tablets */\n  }\n}\n\n@media screen and (max-width: 480px) {\n  html, body {\n    font-size: 0.8rem; /* Smaller font size for mobile phones */\n  }\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
