import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../InfoPages.css'; // Import the CSS file
import '../components/Common.css'; // Import the CSS file

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer>
      <small>
        <a href="/" className="inline-link">Home</a> |&nbsp;
        <a href="/terms" className="inline-link">Terms of Service</a> |&nbsp;
        <a href="/privacy" className="inline-link">Privacy Policy</a> |&nbsp;
        <a href="/about" className="inline-link">Learn More</a>
        <br />
        © 2024 Paulosec LLC. All rights reserved.
      </small>
    </footer>
  );
};

export default Footer;
